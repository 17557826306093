import { createStore } from 'vuex'
import axios from '@/utils/customAxios'
import sessionShare from '@/utils/sessionShare'

export const store = createStore({
  state: () => ({
    accessToken: '',
    myInfo: {},
    leftbarMode: undefined,
    alertInfo: {},
    alertAsync: {},
  }),
  getters: {
    getAccessToken: (state) => {
      return state.accessToken
    },
    getMyInfo: (state) => () => {
      return state.myInfo
    },
    getLeftbarMode: (state) => () => {
      if (state.leftbarMode === undefined) {
        state.leftbarMode = localStorage.getItem('leftbar-mode') || ''
      }
      return state.leftbarMode
    },
    getAlertInfo: (state) => () => {
      return state.alertInfo
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken
    },
    REMOVE_ACCESS_TOKEN(state) {
      sessionShare.logout(state.accessToken)

      state.accessToken = ''
      state.myInfo = {}
      sessionStorage.removeItem('accessToken')
      sessionStorage.removeItem('myInfo')
    },
    SET_MY_INFO(state, payload) {
      state.myInfo = payload
    },
    SET_LEFTBAR_MODE(state, leftbarMode) {
      localStorage.setItem('leftbar-mode', leftbarMode)
      state.leftbarMode = leftbarMode
    },
    OPEN_ALERT(state, payload) {
      const scrollTop =
        window.scrollY || window.document.documentElement.scrollTop
      state.alertInfo = {
        isOpen: true,
        scrollTop: scrollTop,
        ...payload,
      }

      window.document.querySelector('html').style.overflow = 'hidden'
    },
    CLOSE_ALERT(state, isOk) {
      state.alertAsync.resolve(isOk)

      window.document.querySelector('html').style.overflow = 'auto'
      window.scrollTo(0, state.alertInfo.scrollTop)

      state.alertInfo = {
        isOpen: false,
      }
      state.alertAsync.resolve = undefined
      state.alertAsync.reject = undefined
    },
    SET_ALERT(state, { resolve, reject }) {
      state.alertAsync.resolve = resolve
      state.alertAsync.reject = reject
    },
  },
  actions: {
    // signin
    signin(context, payload) {
      return axios({
        method: 'post',
        url: '/api/auth/signin',
        isLoading: true,
        data: payload,
      })
        .then(async (resp) => {
          const resData = resp.data

          if (resData.code === 'C0000') {
            const myInfo = resData.data
            const accessToken = myInfo.accessToken
            const rememberToken = myInfo.rememberToken
            delete myInfo.accessToken
            delete myInfo.rememberToken

            sessionStorage.setItem('accessToken', accessToken)
            sessionStorage.setItem('myInfo', JSON.stringify(myInfo))

            context.commit('SET_ACCESS_TOKEN', accessToken)
            context.commit('SET_MY_INFO', myInfo)

            if (payload.rememberMe === 'Y') {
              localStorage.setItem('rememberToken', rememberToken)
            } else {
              localStorage.removeItem('rememberToken')
            }
          }
          return resp
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    // rememberMe token 체크
    signinByToken(context, payload) {
      return axios({
        method: 'get',
        url: '/api/auth/token-check',
        headers: {
          'x-local-token': payload.rememberToken,
        },
      }).then((resp) => {
        const resData = resp.data
        if (resData.code === 'C0000') {
          const myInfo = resData.data
          const accessToken = myInfo.accessToken
          delete myInfo.accessToken

          sessionStorage.setItem('accessToken', accessToken)
          sessionStorage.setItem('myInfo', JSON.stringify(myInfo))

          context.commit('SET_ACCESS_TOKEN', accessToken)
          context.commit('SET_MY_INFO', myInfo)
        } else {
          context.commit('REMOVE_ACCESS_TOKEN')
        }
      })
    },
    // 로그인 정보를 셋팅
    setSessionInfo(context, { accessToken, myInfo }) {
      context.commit('SET_ACCESS_TOKEN', accessToken)
      // context.commit('SET_REFRESH_TOKEN', refreshToken)
      context.commit('SET_MY_INFO', myInfo)
    },
    signout(context) {
      context.commit('REMOVE_ACCESS_TOKEN')
    },
    // Left Menu (크게, 작게)
    setLeftbarMode(context, leftbarMode) {
      context.commit('SET_LEFTBAR_MODE', leftbarMode)
    },
    openAsyncAlert(context, payload) {
      context.commit('OPEN_ALERT', payload)
      return new Promise((resolve, reject) => {
        context.commit('SET_ALERT', { resolve, reject })
      })
    },
    closeAsyncAlert(context, isOk) {
      context.commit('CLOSE_ALERT', isOk)
    },
  },
})
