import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/pages/IndexPage.vue'),
    meta: {
      layout: 'blank',
      auth: 'ANYONE'
    }
  },
  {
    path: '/main',
    name: 'Main',
    redirect: '/recieve/sms-phone'
  },
  {
    path: '/auth/signin',
    name: 'AuthSignin',
    component: () => import('@/pages/auth/AuthSignin.vue'),
    meta: {
      layout: 'blank',
      auth: 'ANYONE'
    }
  },
  {
    path: '/recieve/sms',
    name: 'RecieveSms',
    component: () => import('@/pages/recieve/RecieveSms.vue'),
    meta: {
      layout: 'type01'
    }
  },
  {
    path: '/recieve/sms-phone',
    name: 'RecieveSmsPhone',
    component: () => import('@/pages/recieve/RecieveSmsPhone.vue'),
    meta: {
      layout: 'type01'
    }
  },
  {
    path: '/recieve/sms-phone-stats',
    name: 'RecieveSmsPhoneStats',
    component: () => import('@/pages/recieve/RecieveSmsPhoneStats.vue'),
    meta: {
      layout: 'type01'
    }
  },
  {
    path: '/recieve/sms-phone-reg',
    name: 'RecieveSmsPhoneReg',
    component: () => import('@/pages/recieve/RecieveSmsPhoneReg.vue'),
    meta: {
      layout: 'type01'
    }
  },
  {
    path: '/recieve/sms-mng',
    name: 'RecieveSmsMng',
    component: () => import('@/pages/recieve/RecieveSmsMng.vue'),
    meta: {
      layout: 'type01'
    }
  },
  {
    path: '/recieve/sms-mng-stats',
    name: 'RecieveSmsMngStats',
    component: () => import('@/pages/recieve/RecieveSmsMngStats.vue'),
    meta: {
      layout: 'type01'
    }
  },
  {
    path: '/recieve/sms-mng-reg',
    name: 'RecieveSmsMngReg',
    component: () => import('@/pages/recieve/RecieveSmsMngReg.vue'),
    meta: {
      layout: 'type01'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
