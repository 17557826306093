import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

import { store } from './store/index.js'
import Grid from 'tui-grid'
import sessionShare from '@/utils/sessionShare'

import './assets/css/style.css'
import 'tui-grid/dist/tui-grid.css'
// import 'bootstrap/dist/css/bootstrap.min.css'

Grid.applyTheme('striped', {
  cell: {
    normal: {
      background: '#f1f3fa',
      border: '#e0e0e0',
      text: '#6c757d',
      showVerticalBorder: true,
      showHorizontalBorder: true,
    },
    header: {
      background: '#313a46',
      border: '#ccc',
      text: '#8391a2',
      showVerticalBorder: true,
      showHorizontalBorder: true,
    },
    rowHeader: {
      background: '#313a46',
      border: '#ccc',
      text: '#000000',
      showVerticalBorder: true,
      showHorizontalBorder: true,
    },
    editable: {
      background: '#fbfbfb',
    },
    selectedHeader: {
      background: '#313a46',
    },
    focused: {
      border: '#418ed4',
    },
    disabled: {
      text: '#b0b0b0',
    },
  },
})

const init = async () => {
  await sessionShare.sleep(200)
  const accessToken = sessionStorage.getItem('accessToken')
  const myInfo = sessionStorage.getItem('myInfo')
  const rememberToken = localStorage.getItem('rememberToken')

  if (accessToken && myInfo) {
    store.dispatch('setSessionInfo', {
      accessToken: accessToken,
      myInfo: JSON.parse(myInfo),
    })
    return Promise.resolve()
  } else if (rememberToken) {
    return store.dispatch('signinByToken', { rememberToken: rememberToken })
  }
  return Promise.resolve()
}

// 새탭 열었을때 session 정보 공유
sessionShare.setEvent()

init().then(() => {
  const app = createApp(App)
  app.use(store)
  app.use(router)
  app.use(mdiVue, {
    icons: mdijs
  })

  app.component(
    'CommPagination',
    defineAsyncComponent(() => import('@/components/CommPagination.vue'))
  )
  app.component(
    'CommGrid',
    defineAsyncComponent(() => import('@/components/CommGrid.vue'))
  )

  app.mount('#app')
})