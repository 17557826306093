import { axios } from '@bundled-es-modules/axios'
import { store } from '@/store/index.js'

const timeout = 30 * 1000

const instance = axios.create({
  baseURL: '',
  timeout: timeout,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

instance.interceptors.request.use(
  (config) => {
    // config.headers['Access-Control-Allow-Origin'] = '*'
    // const accessToken = store.getters['getAccessToken']()
    const accessToken = store.getters['getAccessToken']

    if (accessToken !== undefined && accessToken !== '') {
      config.headers['authorization'] = 'Bearer ' + accessToken
    }

    return config
  },
  (error) => {
    instance.defaults.timeout = timeout // 타임아웃 제한시간 초기화
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    instance.defaults.timeout = timeout

    // const contentType = response.headers['content-type'].toLowerCase()

    // if (contentType.indexOf('json') === -1) {
    //   // 엑셀다운로드
    //   return response.data
    // }

    return response
  },
  (error) => {
    instance.defaults.timeout = timeout // 타임아웃 제한시간 초기화

    console.log('[s] axios : ERROR')
    console.log(error)
    console.log(error.response)
    console.log('[e] axios : ERROR')

    if (error.response === undefined || error.response.status === undefined) {
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      window.location.href = '/auth/signin'
    } else if (
      error.response.status === 403 &&
      error.response.data.code === 'TOKEN_EXPIRED'
    ) {
      store.dispatch('signout').then(() => {
        document.location.href = '/auth/signin'
      })
    } else if (error.response.status === 500) {
      alert('작업중 오류가 발생하였습니다.')
    } else {
      return Promise.reject(error)
    }
  }
)

export default instance
