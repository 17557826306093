<template>
  <div>
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Today Coin</a>

        <div class="collapse navbar-collapse" id="headerNavbars">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/recieve/sms">문자관리</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/recieve/sms-phone">수신문자 관리</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/recieve/sms-phone-reg">수신문자 등록</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/recieve/sms-phone-stats">수신문자 통계</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/recieve/sms-mng">수신문자 관리(v2)</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/recieve/sms-mng-reg">수신문자 등록(v2)</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/recieve/sms-mng-stats">수신문자 통계(v2)</router-link>
            </li>
          </ul>
          <form role="search">
            <button type="button" class="btn btn-light btn-sm" @click="fnSignout">로그아웃</button>
          </form>
        </div>
      </div>
    </nav>
    <main class="container">
      <div class="bg-body-tertiary p-5 rounded">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'LayoutType01',
  components: {},
  setup () {
    const store = useStore()

    const fnSignout = () => {
      store.dispatch('signout').then(() => {
        document.location.href = '/auth/signin'
      })
    }

    return {
      fnSignout
    }
  }
}
</script>