const sessionShare = {
  setEvent: () => {
    window.addEventListener('storage', (e) => {
      if (!e.newValue) {
        return
      }
      if (e.key === 'getSessionStrage') {
        const accessToken = sessionStorage.getItem('accessToken')
        const myInfo = sessionStorage.getItem('myInfo')

        if (accessToken) {
          const shareData = {
            accessToken: accessToken,
            myInfo: myInfo,
          }
          localStorage.setItem('sessionStorage', JSON.stringify(shareData))
          localStorage.removeItem('sessionStorage')
        }
      } else if (e.key === 'sessionStorage' && !sessionStorage.length) {
        const shareData = JSON.parse(e.newValue)

        for (let key in shareData) {
          sessionStorage.setItem(key, shareData[key])
        }
      } else if (e.key === 'logout') {
        const accessToken = sessionStorage.getItem('accessToken')

        if (e.newValue === accessToken) {
          sessionStorage.removeItem('accessToken')
          sessionStorage.removeItem('myInfo')
          window.location.href = '/auth/signin'
        }
      }
    })

    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStrage', '1')
      localStorage.removeItem('getSessionStrage')
    }
  },
  logout: (accessToken) => {
    localStorage.setItem('logout', accessToken)
    localStorage.removeItem('logout')
  },
  sleep: (ms) => {
    return new Promise((r) => setTimeout(r, ms))
  },
}

export default sessionShare
