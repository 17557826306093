<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'

import LayoutBlank from '@/pages/LayoutBlank.vue'
import LayoutType01 from '@/pages/LayoutType01.vue';

export default {
  name: 'App',
  components: {},
  setup () {
    const route = useRoute()
    const store = useStore()
    const myInfo = store.getters.getMyInfo()

    const resolveLayout = computed(() => {
      if (route.name === null || route.name === undefined) {
        return null
      }

      if (myInfo === undefined || myInfo.userNm === undefined) {
        if (!route.meta || route.meta.auth !== 'ANYONE') {
          window.location.href = '/auth/signin'
          return LayoutBlank
        }
      }

      if (route.meta.layout === 'blank') {
        return LayoutBlank
      }

      return LayoutType01
    })

    return {
      resolveLayout
    }
  }
}
</script>
